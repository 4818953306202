import type { FormControlLabelProps } from '@mui/material';
import { FormControlLabel, FormHelperText } from '@mui/material';
import { useField } from 'formik';
import { Fragment } from 'react';

export default function FormFormControlLabel( {
	name,
	onChange,
	...props
}: {
	name: string
} & Omit<FormControlLabelProps, 'form'> ) {
	const [ { value }, { touched, error }, { setValue } ] = useField( name );
	
	return (
		<Fragment>
			<FormControlLabel
				checked={value ?? false}
				onChange={( e, checked ) => {
					setValue( checked );
					onChange?.( e, checked );
				}}
				{...props}
				sx={{ ...props?.sx }}
			/>
			{touched && Boolean( error ) && <FormHelperText error>{error}</FormHelperText>}
		</Fragment>
	);
}
