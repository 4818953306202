import FormAddress from '@/components/form/fields/address';
import PageSection from '@/components/page/section';
import useGetDeviceInfo from '@/hooks/useGetDeviceInfo';
import { useTranslation } from 'react-i18next';

export default function VendorAddress() {
	const { isCloverDevice } = useGetDeviceInfo();
	const { t } = useTranslation();
	
	if ( isCloverDevice ) return null;
	
	return (
		<PageSection primary={t( 'common:vendor-address' )}>
			{!isCloverDevice && <FormAddress name='addresses.[0]'/>}
		</PageSection>
	);
}
