export function hasFullAddress( address ) {
	return address?.line1 && address?.city && address?.state && address?.country && address?.postalCode;
}

export function hasNoAddress( address ) {
	return !address?.line1 && !address?.city && !address?.state && !address?.country && !address?.postalCode;
}

export function getAddressErrors( address ) {
	return {
		addresses: [ {
			line1     : !address?.line1 ? 'Line1 is required' : null,
			city      : !address?.city ? 'City is required' : null,
			state     : !address?.state ? 'State is required' : null,
			country   : !address?.country ? 'Country is required' : null,
			postalCode: !address?.postalCode ? 'Postal Code is required' : null,
		} ],
		
	};
}

