import { useField, useFormikContext } from 'formik';
import type { ReactNode } from 'react';
import { useEffect } from 'react';
import type { SelectTableProps } from '../../selectTable';
import SelectTable from '../../selectTable';

type FormSelectTableProps<S extends { id: string }, T, U = S> = {
	name: string,
	label?: ReactNode
} & Omit<SelectTableProps<S, T, U>, 'data' | 'setData'>;

export default function FormSelectTable<S extends { id: string }, T, U = S>( {
	name,
	label,
	...props
}: FormSelectTableProps<S, T, U> ) {
	const [ , { touched, error, value }, { setValue, setTouched } ] = useField( name );
	const formik = useFormikContext();
	
	useEffect( () => {
		if ( formik.isSubmitting ) {
			setTouched( true );
		}
	}, [ formik.submitCount ] );
	
	return (
		<SelectTable
			data={value}
			setData={setValue}
			textFieldProps={{
				error: touched && Boolean( error ),
				label: touched && error || label,
			}}
			{...props}
			tableProps={{ hideAddIcon: false, ...props.tableProps }}
		/>
	);
}
