import CSVImportModal from '@/components/fileUploading/CSVImportModal';
import PageSection from '@/components/page/section';
import useUserInfo from '@/providers/auth/useUserInfo';
import { useModal } from '@/providers/modal';
import { isSuperAdmin } from '@/utils/isSuperAdmin';
import { Button, Grid, Theme, Typography, useMediaQuery } from '@mui/material';
import { useFormikContext } from 'formik';
import { nanoid } from 'nanoid';
import { useTranslation } from 'react-i18next';

export default function VendorFormCSVUpload() {
	const { t } = useTranslation();
	const formik = useFormikContext();
	const { user } = useUserInfo();
	const { showModal } = useModal();
	const isMobile = useMediaQuery<Theme>( ( { breakpoints } ) => breakpoints.down( 'sm' ) );
	
	const superAdmin = isSuperAdmin( user );
	
	return (
		<PageSection primary='Menus' secondary={t( 'common:csv-des' )}>
			<Grid item xs={12}>
				{!isMobile && superAdmin ? (
					<Button
						onClick={() => showModal( CSVImportModal, { maxWidth: 'sm' }, {
							headers     : [
								t( 'common:id' ),
								t( 'common:sku' ),
								t( 'common:item' ),
								t( 'common:name' ),
								t( 'common:category' ),
								t( 'common:description' ),
								t( 'common:cost' ),
								t( 'common:price' ),
								t( 'common:id-unit' ),
								t( 'common:code' ),
							],
							headerValues: [
								'id',
								'sku',
								'item',
								'name',
								'category',
								'description',
								'cost',
								'price',
								'uomId',
								'code',
							],
							setData     : ( data ) => {
								formik.setFieldValue( 'imported', data.map( ( item ) => ( {
										id   : item?.uomId || nanoid(),
										sku  : item.sku,
										name : item.name,
										code : item?.code,
										cost : +item.cost?.replace( /[^\d.-]/g, '' ) || 0,
										price: +item.price?.replace( /[^\d.-]/g, '' ) || 0,
										item : {
											id         : item?.id,
											name       : item.item || '',
											description: item.description,
											categories : [ { name: item.category } ],
										},
									} ) ),
								);
							},
						} )}>
						{t( 'common:import' )}
					</Button>
				) : (
					<Typography textAlign='center'>{t( 'management:ask-support-to-upload-menus' )}</Typography>
				)}
			</Grid>
		</PageSection>
	);
}
