import PageSection from '@/components/page/section';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import LocationSelect from '../../../../formSelects/locationSelect';

export default function VendorFormLocations() {
	const { t } = useTranslation();
	
	return (
		<PageSection primary={t( 'common:locations' )} secondary={t( 'common:location-des' )}>
			<Grid item xs={12}>
				<LocationSelect
					multiple
					fullWidth
					name='servingLocations'
					onAdd={undefined}
				/>
			</Grid>
		</PageSection>
	);
}
