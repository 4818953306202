import FormSelectTable from '@/components/form/fields/formSelectTable';
import { ModalFormWrapper } from '@/components/form/modal';
import PageSection from '@/components/page/section';
import { useModal } from '@/providers/modal';
import { Box, Divider, Grid, MenuItem, Stack, Typography } from '@mui/material';
import { useFormikContext } from 'formik';
import { differenceWith, intersectionWith } from 'lodash-es';
import React, { Fragment, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { UomsRead } from '../../../../../data/management/uom.graphql';
import currencyFormat from '../../../../../helpers/currencyFormat';
import { DeepPartial } from '../../../../../types/deepPartial';
import { Menu, QueryUomsReadArgs, Uom } from '../../../../../types/schema';
import VendorMenuForm from '../form/vendorMenu';

export default function VendorMenus() {
	const { showModal } = useModal();
	const { t } = useTranslation();
	const formik = useFormikContext<Menu>();
	
	useEffect( () => {
		const newUoms = differenceWith( formik.values.uoms, formik.initialValues.uoms, ( a, b ) => a.id === b.id );
		const oldUoms = intersectionWith( formik.values.uoms, formik.initialValues.uoms, ( a, b ) => a.id === b.id );
		const uoms = [ ...oldUoms, ...newUoms ];
		formik.setFieldValue( 'uoms', uoms );
	}, [ formik.values.uoms.length ] );
	
	return (
		<PageSection primary={t( 'common:from-item' )}>
			<Box sx={{ '.MuiTableContainer-root .MuiTable-root': { minWidth: '500px !important' } }}>
				<FormSelectTable<Uom, QueryUomsReadArgs, DeepPartial<Uom>>
					disableCloseOnSelect
					name='uoms'
					queryKey='uom'
					blurOnSelect={false}
					query={UomsRead}
					textFieldProps={{ placeholder: t( 'common:search-name-unit' ) }}
					variables={{ options: { filter: { selected: true, item: { company: formik.values.company?.id } } } }}
					// subscription={{ UOM: 'COMPANY' }}
					getOptionLabel={( {
						name,
						sku,
						cost,
						vendorSku,
						item,
						code,
						quantity,
					}: Uom ) => `${name} ${item.name} ${sku} ${code} ${quantity} ${cost} ${vendorSku} ${item.description
						? `(${item.description})`
						: ''}`}
					renderOption={( props, option ) =>
						(
							<Fragment key={option.id ?? ''}>
								<MenuItem
									{...props}
									selected={Boolean( formik.values.uoms?.find( ( uom ) => uom.item?.name === option.item?.name && uom.sku === option.sku ) )}>
									<Stack sx={{ width: '100%' }}>
										<Grid container spacing={1}>
											<Grid item xs={8}>
												<Typography fontWeight={500}>
													{option.item.name}
												</Typography>
											</Grid>
											<Grid item xs={4}>
												<Typography fontWeight={500} sx={{ textAlign: 'right' }}>
													{option.name} {currencyFormat( option.cost ) || '$0.00'}
												</Typography>
											</Grid>
										</Grid>
										<Grid
											container
											spacing={1}
											sx={{ '.MuiTypography-root': { fontSize: { xs: 11, sm: 13 } } }}>
											<Grid item xs={4}>
												{option.code && (
													<Typography color='text.secondary'>
														#{option.code}
													</Typography>
												)}
											</Grid>
											<Grid item xs={4}>
												{option.sku ? (
													<Typography sx={{ textAlign: 'center' }} color='text.secondary'>
														{option.sku || ''}
													</Typography>
												) : ''}
											</Grid>
											<Grid item xs={4}>
												{option.quantity ? (
													<Typography sx={{ textAlign: 'right' }}>
														{option.quantity} available
													</Typography>
												) : ''}
											</Grid>
										</Grid>
									</Stack>
								</MenuItem>
								<Divider sx={{ my: 1 }}/>
							</Fragment>
						)}
					checkDuplicate={( uom, newValue: Uom ) => uom?.id === newValue?.id}
					tableProps={{
						headers : [ t( 'common:name' ), t( 'common:unit' ), t( 'common:sku' ), t( 'common:cost' ) ],
						columns : ( uom ) => [ uom.item.name, uom.name, uom.sku, currencyFormat( uom.cost ) ],
						onClick : ( uom ) => {
							showModal( VendorMenuForm, { maxWidth: 'sm' }, {
								Wrapper : ModalFormWrapper,
								uom,
								onSubmit: ( uom ) => {
									formik.setFieldValue( 'uoms',
										[ ...formik.values.uoms.filter( ( uomA ) => uomA.id !== uom.id ), uom ] );
								},
							} );
						},
						editable: {
							onAdd: () => showModal( VendorMenuForm, { maxWidth: 'sm' }, {
								Wrapper : ModalFormWrapper,
								onSubmit: ( uom ) => {
									formik.setFieldValue( 'uoms', [ ...formik.values.uoms, uom ] );
								},
							} ),
						},
					}}
				/>
			</Box>
		</PageSection>
	);
}

