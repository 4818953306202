import { BaseGraphQLAutocompleteProps } from '@/components/form/fields/types';
import ResponsiveGraphQlAutoComplete from '@/components/responsiveAutoComplete';
import { Grow, MenuItem, Paper, Typography } from '@mui/material';
import { isEmpty } from 'lodash-es';
import { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import EnhancedTable from './enhancedDisplay/enhancedTable';
import type { EnhancedDisplayProps, EnhancedTableProps } from './enhancedDisplay/helpers';

export type SelectTableProps<S extends { id: string }, T, U = S> = {
	onDelete?: () => void,
	modifyValue?: ( value: S ) => U | U[],
	checkDuplicate?: ( item: U, newValue: U | U[] ) => boolean,
	data: U[],
	setData?: ( items: U[] ) => void,
	hideSelector?: boolean,
	tableProps: Omit<EnhancedDisplayProps<U> & EnhancedTableProps<U>, 'data' | 'setData'>,
	selectedOption?: ( option: S, tableData: U[] ) => boolean
} & BaseGraphQLAutocompleteProps<S, T>;

export default function SelectTable<S extends { id: string }, T, U extends { id?: string } = S>( {
	data,
	setData,
	modifyValue,
	hideSelector,
	onDelete,
	checkDuplicate,
	tableProps,
	selectedOption,
	...props
}: SelectTableProps<S, T, U> ) {
	const { t } = useTranslation();
	const [ open, setOpen ] = useState( false );
	
	function PaperComponent( { children, data } ) {
		const dataSet = Object.values( data )[ 0 ] as any;
		const items = !isEmpty( data ) && dataSet?.count >= 10 ? dataSet?.count : dataSet?.items?.length;
		
		return (
			<Grow in={open}>
				<Paper
					variant='blur'
					sx={{
						display      : 'flex',
						flexDirection: 'column',
					}}>
					{children}
					{items >= 10 ? (
						<Typography
							textAlign='center'
							color='text.secondary'
							onMouseDown={( e ) => e.preventDefault()}>
							{t( 'commerce:search-to-see-more' )}
						</Typography>
					) : undefined}
				</Paper>
			</Grow>
		);
	}
	
	return (
		<Fragment>
			{!hideSelector && (
				<ResponsiveGraphQlAutoComplete
					clearTextOnInputChange
					open={open}
					value={null}
					renderOption={( liProps, option: any ) => (
						<MenuItem
							{...liProps}
							key={option.id ?? ''}
							selected={selectedOption?.( option, data )}>
							{props.getOptionLabel?.( option ) || option?.name || ''}
						</MenuItem>
					)}
					textFieldProps={{ autoComplete: 'off' }}
					PaperComponent={PaperComponent}
					onOpen={() => setOpen( true )}
					onClose={() => setOpen( false )}
					onChange={( e, value: any ) => {
						if ( !value ) return;
						const newValue = modifyValue?.( value ) || value;
						if ( value?.id && data?.find( ( item ) => checkDuplicate?.( item, newValue ) ?? item?.id === value.id ) ) {
							return;
						}
						setData?.( Array.isArray( newValue )
							? [ ...data || [], ...newValue ]
							: [ ...data || [], newValue ],
						);
					}}
					{...props}
				/>
			)}
			<EnhancedTable
				hideAddIcon
				data={data}
				setData={setData}
				pageSectionProps={{ hide: true, boxProps: { sx: { mt: 1 } } }}
				{...tableProps}
				editable={{
					onDelete: ( item ) => setData?.( data?.filter( ( row ) => row.id !== item.id ) ),
					...tableProps.editable,
					
				}}
				tableContainerProps={{
					sx: {
						border      : 1,
						borderRadius: 2,
						borderColor : 'divider',
						bgcolor     : 'background.default',
					},
				}}
			/>
		</Fragment>
	);
}
