import FormFormControlLabel from '@/components/form/fields/controlLabel';
import FormPhoneField from '@/components/form/fields/phoneField';
import FormTextField from '@/components/form/fields/textField';
import PageSection from '@/components/page/section';
import { Grid, Switch } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

export default function VendorFormDetails() {
	const { t } = useTranslation();
	return (
		<PageSection
			primary={t( 'common:details' )}
			actions={(
				<FormFormControlLabel
					name='active'
					control={<Switch/>}
					label={t( 'common:active' )}
				/>
			)}>
			<Grid container spacing={2}>
				<Grid item xs={12} md={6}>
					<FormTextField
						fullWidth
						required
						name='vendorName'
						label={t( 'common:company-name' )}
					/>
				</Grid>
				<Grid item xs={12} md={6}>
					<FormTextField
						fullWidth
						name='vendorContact'
						label={t( 'common:contact' )}
					/>
				</Grid>
				<Grid item xs={12} md={6} sx={{}}>
					<FormTextField
						fullWidth
						required
						name='vendorEmail'
						label={t( 'common:email' )}
						type='email'
						autoComplete='email'
						helperText={t( 'common:email-helper' )}
					/>
				</Grid>
				<Grid item xs={12} sm={6}>
					<FormPhoneField label={t( 'common:phone' )} name='vendorPhone'/>
				</Grid>
				<Grid item xs={12}>
					<FormTextField
						fullWidth
						multiline
						name='note'
						label={t( 'management:private-note' )}
						placeholder={t( 'common:private-note-detail' )}
						variant='outlined'
						inputProps={{ maxLength: 3000 }}
						rows={3}
						helperText={t( 'common:private-note-visible' )}
					/>
				</Grid>
			</Grid>
		</PageSection>
	);
}
